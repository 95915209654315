(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("userSettingsGeneralAutoResponderController", userSettingsGeneralAutoResponderController);

    function userSettingsGeneralAutoResponderController($rootScope, $state, $scope, $http, $timeout, $filter, $mdDialog, coreData, coreDataSettings, errorHandling) {
        var vm = this;
        vm.autoResponderWarning = -1;
        vm.subject = null;
        vm.body = "";
        vm.externalResponse = "";
        vm.enableAutoResponder = false;
        vm.autoRespondOnDirectMailOnly = true;
        vm.useActiveDateRange = false;
        vm.startTime = null;
        vm.endTime = null;
        vm.loading = true;

        $scope.internalEditorScope = {};
        $scope.externalEditorScope = {};

        // Functions
        vm.reloadState = reloadState;
        vm.save = save;

        activate();

        /////////////////////

        async function activate() {
            $scope.$watch(() => vm.body, markDirty);
            $scope.$watch(() => vm.externalResponse, markDirty);
            $scope.$watch(() => vm.startTime, onTimeFrameChanged);
            $scope.$watch(() => vm.endTime, onTimeFrameChanged);

            await load();
        }

        async function load(reset) {
            vm.loading = true;

            try {
                const response = await $http.get('~/api/v1/settings/auto-responder/true');

                var result = response.data.autoResponderSettings;
                vm.enableAutoResponder = result.enabled;
                vm.autoRespondOnDirectMailOnly = result.autoRespondOnDirectMailOnly;
                vm.subject = result.subject;

                if (result.body.indexOf("attlinkedfileid:") > -1)
                    result.body = result.body.split('\"attlinkedfileid:').join('"' + location.origin + stSiteRoot + 'attachedfile?data=');
                if (result.externalReply.indexOf("attlinkedfileid:") > -1)
                    result.externalReply = result.externalReply.split('\"attlinkedfileid:').join('"' + location.origin + stSiteRoot + 'attachedfile?data=');

                vm.body = unwrapTextForFroala(result.body);
                vm.externalResponse = unwrapTextForFroala(result.externalReply);
                vm.autoResponderWarning = coreDataSettings.userPermissions.spamRelayOption;
                vm.useActiveDateRange = result.useActiveDateRange;
                vm.startTime = vm.useActiveDateRange ? new Date(moment(result.startDateUtc)) : new Date(moment());
                vm.endTime = vm.useActiveDateRange ? new Date(moment(result.endDateUtc)) : new Date(moment().add(1, 'days'));
                vm.externalAudience = result.externalAudience;

                if (reset) $timeout(() => $rootScope.$broadcast('DateModelReset'), 350);

            } catch (e) {
                errorHandling.report(e);
            } finally {
                $timeout(function () { vm.pageForm.$setPristine(); vm.loading = false; });
            }
        }

        async function reloadState(form) {
            await load(true);
            $timeout(function () { vm.pageForm.$setPristine(); });
        }

        function unwrapTextForFroala(content) {
            // Check for a wrapper div. If it is present, remove it to prevent issues with paragraph styling.
            var $content = $(content);
            if ($content.length === 1 && $content[0].nodeName.toUpperCase() === "DIV" && $content.children("div,h1,h2,h3,h4,pre,blockquote,ul,ol,p").length > 0)
                return $content.html();
            return content;
        }

        function onTimeFrameChanged() {
            if (vm.startTime > vm.endTime) {
                vm.endTime = moment(vm.startTime).add(1, 'd').toDate();
            }
        }

        function markDirty(newValue, oldValue) {
            if (oldValue != newValue && !vm.loading)
                vm.pageForm.$setDirty();
        }

        function save(event, form) {
            const internalBody = $scope.internalEditorScope.getHtml();
            const externalBody = $scope.externalEditorScope.getHtml();

            $timeout(function () {
                if (form.$invalid)
                    return;

                var params = {
                    subject: vm.subject,
                    body: internalBody,
                    externalReply: externalBody,
                    enabled: vm.enableAutoResponder,
                    autoRespondOnDirectMailOnly: vm.autoRespondOnDirectMailOnly,
                    useActiveDateRange: vm.useActiveDateRange,
                    startDateUtc: vm.startTime,
                    endDateUtc: vm.endTime,
                    isHTML: true,
                    externalAudience: vm.externalAudience
                };

                $http
                    .post('~/api/v1/settings/auto-responder', JSON.stringify({ autoResponderSettings: params }))
                    .then(onSaveSuccess, errorHandling.report);

                function onSaveSuccess(success) {
                    form.$setPristine();
                    $rootScope.$broadcast('user-settings:changed');
                }
            });
        }
    };
})();