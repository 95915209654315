(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminEventActionEditController", sysAdminEventActionEditController);

	function sysAdminEventActionEditController($scope, $mdDialog, $filter, $timeout, eventGuid, eventId, newAction, availableEvents, availableVariables, action, defaultEmail) {
        var vm = this;
        $scope.action = action;
		$scope.eventId = eventId;
		$scope.eventGuid = eventGuid;
		$scope.newAction = newAction;
		$scope.availableEvents = availableEvents;
		$scope.isValid = true;
		$scope.availableVariables = availableVariables[eventId];
		$scope.variables = {};
		angular.forEach($scope.availableVariables, function (variable) {
			$scope.variables[variable] = getVariableName(variable);
		});
		$scope.customVariables = [{ key: 'var_dropdown', options: $scope.variables, icon: 'cog', title: $filter('translate')("SIGNATURES_CUSTOM_VARIABLES"), callback: variableCallback }];
		$scope.repeatTime = 0;
		$scope.defaultEmail = defaultEmail;
		$scope.possibleActions = $scope.availableEvents[eventId].possibleActions;
		$scope.currentActionKey = !newAction ? $scope.action.key : "";
		$scope.addAddress = '';
		$scope.recipient = '';
		$scope.fromAddress = '';
		vm.toAddress = '';
		$scope.process = '';
		$scope.arguments = '';
		$scope.subject = '';
		$scope.shortBody = '';
		$scope.body = '';
        $scope.recipientOptions = [];
        $scope.recipientType = "None";
		$scope.repeatOptions = [
			{ value: 0, translation: $filter('translate')("NONE") },
			{ value: 1, translation: $filter('translate')("EVENT_ACTIONS_MINUTES", { minutes: 1 }) },
			{ value: 2, translation: $filter('translate')("EVENT_ACTIONS_MINUTES", { minutes: 2 }) },
			{ value: 5, translation: $filter('translate')("EVENT_ACTIONS_MINUTES", { minutes: 5 }) },
			{ value: 15, translation: $filter('translate')("EVENT_ACTIONS_MINUTES", { minutes: 15 }) },
			{ value: 30, translation: $filter('translate')("EVENT_ACTIONS_MINUTES", { minutes: 30 }) },
			{ value: 60, translation: $filter('translate')("EVENT_ACTIONS_HOURS", { hours: 60 / 60 }) },
			{ value: 120, translation: $filter('translate')("EVENT_ACTIONS_HOURS", { hours: 120 / 60 }) },
			{ value: 300, translation: $filter('translate')("EVENT_ACTIONS_HOURS", { hours: 300 / 60 }) },
			{ value: 720, translation: $filter('translate')("EVENT_ACTIONS_HOURS", { hours: 720 / 60 }) },
			{ value: 1440, translation: $filter('translate')("EVENT_ACTIONS_HOURS", { hours: 1440 / 60 }) }
		];
		$scope.alertUserEditorScope = {};
		$scope.alertEditorScope = {};
		$scope.messageEditorScope = {};

		// Functions
		$scope.getSetAction = getSetAction;
		$scope.parseRepeatTime = parseRepeatTime;
		$scope.getVariableName = getVariableName;
		$scope.save = save;
		$scope.delete = deleteThis;
		$scope.cancel = cancel;

		activate();

		///////////////////////

		function activate() {
			loadActionVars(false);
			$scope.parseRepeatTime();
		}

		function loadActionVars(modifyingAction) {
			$scope.sendEmail = false;
			$scope.browserNotify = true;
			$scope.subject = $filter('translate')('EVENT_ACTIONS_DEFAULTS_' + $scope.eventId + '_SUBJECT');
			$scope.body = $filter('translate')('EVENT_ACTIONS_DEFAULTS_' + $scope.eventId + '_BODY');
			$scope.shortBody = $filter('translate')('EVENT_ACTIONS_DEFAULTS_' + $scope.eventId + '_SHORT');


            $scope.recipientOptions = [];
			
            if ($scope.currentActionKey && $scope.currentActionKey !== "") {

                $scope.actionTemplate = $.grep($scope.possibleActions, function (action) { return action.key === $scope.currentActionKey; });
                if ($scope.actionTemplate[0] && $scope.actionTemplate[0].supportedActionRecipients) {
                    var supportedRecipientTypes = $scope.actionTemplate[0].supportedActionRecipients;
                    var recipients = supportedRecipientTypes.replace(/\s+/g, '').split(',').reverse();
                    recipients.forEach(r => {
                        $scope.recipientOptions.push({
                            value: r,
                            translation: $filter('translate')("EVENT_ACTIONS_RECIPIENT_TYPE_" + r.toUpperCase(),
                                { level: $scope.level })
                        });
                    });
                } 
            }
			if (!$scope.newAction) {
                $scope.actionTemplate = $.grep($scope.possibleActions, function (action) { return action.key === $scope.currentActionKey; });
                if ($scope.actionTemplate[0]) {
                    $scope.action.supportedActionRecipients = $scope.actionTemplate[0].supportedActionRecipients; 
                }

				angular.forEach($scope.action.inputs, function (input) {
					switch (input.descriptionResourceId) {
						//case "@EventInput_Owner":
						//	$scope.defaultEmail = input.value;
						//	break;
						case "@EventInput_Process":
							$scope.process = input.value;
							break;
                        case "@EventInput_RecipientType":
                            $scope.recipientType = input.value;
                            break;
						case "@EventInput_Arguments":
							$scope.arguments = input.value;
							break;
						case "@EventInput_FromAddress":
							$scope.fromAddress = input.value;
							break;
						case "@EventInput_ToAddress":
							vm.toAddress = input.value;
                        case "@EventInput_AddressToAdd":
							$scope.addAddress = input.value;
							break;
						case "@EventInput_Recipient":
							$scope.recipient = input.value;
							break;
						case "@EventInput_Subject":
							if (!modifyingAction)
								$scope.subject = input.value;
							break;
						case "@EventInput_EmailShort":
							if (!modifyingAction)
								$scope.shortBody = input.value;
							break;
						case "@Body":
							if (!modifyingAction)
								$scope.body = input.value;
							break;

					}
				});
			}

            if ($scope.recipientType === "None" && $scope.recipientOptions.length > 0) {
                $scope.recipientType = $scope.recipientOptions[0].value;
            } 

			if (modifyingAction) {
				if (!$scope.fromAddress)
					$scope.fromAddress = $scope.defaultEmail;
				if (!vm.toAddress)
					vm.toAddress = $scope.defaultEmail;
				$scope.sendEmail = !!$scope.defaultEmail;
				if ($scope.recipientOptions.length > 0 && !$scope.recipientOptions.some(x => x.value === $scope.recipientType)) {
					$scope.recipientType = $scope.recipientOptions[0].value;
				}

			}
		}

		function getSetAction(value) {
			if (arguments.length) { // Set
				$scope.currentActionKey = value;

				if (!$scope.newAction) {
					$scope.newTemplate = $.grep($scope.possibleActions, function (action) { return action.key === $scope.currentActionKey; });
					if ($scope.newTemplate[0]) {
						var oldId = $scope.action.id;
						$scope.action = $scope.newTemplate[0];
						$scope.action.id = oldId;
						$scope.fromAddress = $scope.defaultEmail;
						vm.toAddress = $scope.defaultEmail;
						$scope.sendEmail = !!$scope.defaultEmail;
                    }
				}
				loadActionVars(true);
            } else { // Get
				return $scope.currentActionKey;
			}
		}

		function parseRepeatTime() {
			var parsedRepeat = $scope.action.constraintFrequency.split(':');
			$scope.repeatTime = Number(parsedRepeat[0] > 0 ? parsedRepeat[0] * 60 : 0) + Number(parsedRepeat[1] > 0 ? parsedRepeat[1] : 0);
		}

		function getVariableName(variable) {
			// Regular expression variable causes an error in HTML, so need to do this conversion here
			return $filter("translate")("EVENT_CONDITIONS_" + variable.toUpperCase().replace(/#/g, ""));
		}

		function variableCallback(cmd, val, scope) {
			$scope.form.$setDirty(); $scope.$applyAsync();
			if (scope.insertHtml) {
				scope.insertHtml(val);
			}
		}

		function getConstraintTime() {
			var hours = Math.floor($scope.repeatTime / 60);
			var minutes = Math.floor($scope.repeatTime % 60);
			return (hours < 10 ? ('0' + hours) : hours) + ':' + (minutes < 10 ? ('0' + minutes) : minutes) + ':00';
		}

		function save() {
			let bodyHtml = $scope.body;
			switch ($scope.currentActionKey) {
				case "AlertUserAction":
					bodyHtml = $scope.alertUserEditorScope.getHtml();
					break;
				case "AlertAction":
					bodyHtml = $scope.alertEditorScope.getHtml();
					break;
				case "EmailAction":
					bodyHtml = $scope.messageEditorScope.getHtml();
					break;
			}

			$timeout(function () {
				if ($scope.newAction) {
					$scope.newTemplate = $.grep($scope.possibleActions, function (action) { return action.key === $scope.currentActionKey; });
					if ($scope.newTemplate[0]) {
						// do a "deep copy" of the selected template
						$scope.action = JSON.parse(JSON.stringify($scope.newTemplate[0]));
					}
					else
						return;
					$scope.action.id = '';
				}
				$scope.action.defaultFrequencyInMinutes = $scope.repeatTime;
				$scope.action.constraintFrequency = getConstraintTime();
				angular.forEach($scope.action.inputs, function (input) {
					switch (input.descriptionResourceId) {
						case "@EventInput_Owner":
							input.value = $scope.defaultEmail;
							break;
                        case "@EventInput_RecipientType":
                            input.value = $scope.recipientType;
                            break;
                        case "@EventInput_Process":
							input.value = $scope.process;
							break;
						case "@EventInput_Arguments":
							input.value = $scope.arguments;
							break;
						case "@EventInput_FromAddress":
							input.value = $scope.fromAddress;
							break;
						case "@EventInput_ToAddress":
                            input.value = vm.toAddress;
							break;
						case "@EventInput_AddressToAdd":
							input.value = $scope.addAddress;
							break;
						case "@EventInput_Recipient":
							input.value = $scope.recipient;
							break;
						case "@EventInput_Subject":
							input.value = $scope.subject;
							break;
						case "@EventInput_EmailShort":
							input.value = $scope.shortBody;
							break;
						case "@Body":
							input.value = bodyHtml;
							break;

					}
				});
				$mdDialog.hide({ action: $scope.action, deleteAction: false });
			});
		}

		function deleteThis() {
			$mdDialog.hide({ action: $scope.action, deleteAction: true });
		}

		function cancel() {
			$mdDialog.cancel();
		}
	}

})();
