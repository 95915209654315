(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("browserNotificationsDialogController", browserNotificationsDialogController);

	function browserNotificationsDialogController($scope, $mdDialog, $filter, $http) {
		var vm = this;
		vm.imageFile = null;

		// Functions
		vm.doClose = doClose;
		activate();

		////////////////////////

		function activate() {
			// Opera 8.0+
			var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

			// Firefox 1.0+
			var isFirefox = typeof InstallTrigger !== 'undefined';

			// Safari 3.0+ "[object HTMLElementConstructor]" 
			var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);

			// Internet Explorer 6-11
			var isIE = /*@cc_on!@*/false || !!document.documentMode;

			// Edge 20+
			var isEdge = !isIE && !!window.StyleMedia;

			// Chrome 1+
			var isChrome = !!window.chrome && !!window.chrome.webstore;

			// Blink engine detection
			var isBlink = (isChrome || isOpera) && !!window.CSS;

			if (isOpera)
				vm.imageFile = null;// (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-opera.jpg";
			else if (isFirefox)
				vm.imageFile = (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-firefox.jpg";
			else if (isSafari)
				vm.imageFile = null;// (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-safari.jpg";
			else if (isIE)
				vm.imageFile = null;// (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-ie.jpg";
			else if (isEdge)
				vm.imageFile = (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-edge.jpg";
			else if (isChrome || isBlink)
				vm.imageFile = (stSiteRoot || '/') + "interface/img/browser-messages/browser-notifications-chrome.jpg";
		}

		function doClose() {
			$mdDialog.hide();
		}
	}

})();
