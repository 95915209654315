(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("systemDomainSslSetupModalController", systemDomainSslSetupModalController);

    function systemDomainSslSetupModalController($rootScope, $scope, $mdDialog, $filter, $http, $translate, $timeout, $q,
        successHandling, claimsService, errorHandling, options) {
        options = clone(options || {});

        var vm = this;
        vm.page = 'start';
        vm.sslCertificate = options.sslCertificate || {};
        vm.hostnames = options.hostnames || [];
        vm.canSetupAcme = options.canSetupAcme || false;
        vm.uploadedCertificate = undefined;

        // Functions
        vm.setupSslCertificateEvents = setupSslCertificateEvents;
        vm.save_generate_config = save_generate_config;
        vm.step1_next = step1_next;
        vm.remove_cert = remove_cert;
        vm.upload_cert = upload_cert;
        vm.cancel = cancel;
        vm.back = back;

        activate();

        /////////////////////

        function activate() {
            // Fixup inputs
            vm.sslCertificate.cert_setup_type = vm.sslCertificate.cert_setup_type || 1;
            vm.sslCertificate.acme_provider = vm.sslCertificate.acme_provider || 1;
            vm.hostnamesAsText = vm.hostnames.sort().join("\n");

            if (vm.sslCertificate.cert_guid) {
                vm.page = "edit-uploaded-cert";
            }
            else {
                // Setup events that have to happen after DOM elements are configured
                $timeout(setupSslCertificateEvents, 0);
            }
        }

        function setupSslCertificateEvents() {
            document.getElementById('sslSetupUploadFile')
                .addEventListener('change', () => {
                    $scope.$applyAsync(() => {
                        let files = document.getElementById('sslSetupUploadFile').files;
                        vm.uploadedCertificate = !!(files && files.length);
                    });
                });
        }

        async function step1_next() {
            if (vm.sslCertificate.cert_setup_type == 1) {
                vm.validatingCertificate = true;
                let certOk = await validate_cert();
                if (certOk) {
                    $scope.$applyAsync(() => {
                        vm.page = 'upload-cert';
                    });
                }
            } else if (vm.sslCertificate.cert_setup_type == 2) {
                vm.page = 'generate-hostname-check';

                vm.hostnamesFromText = vm.hostnamesAsText.split('\n');

                validate_hostnames();
            }
        }

        async function remove_cert() {
            $mdDialog.hide({ delete: true });
        }

        async function read_uploaded_file() {
            let defer = $q.defer();
            const reader = new FileReader();

            let file = document.getElementById('sslSetupUploadFile').files[0];
            if (file) {
                reader.addEventListener("load", onFileLoaded);
                reader.readAsDataURL(file);
            }

            async function onFileLoaded() {
                try {
                    let uploadedFile = {
                        filename: file.name,
                        password: vm.sslPassword || '',
                        length: file.size,
                        data: reader.result.split(',')[1]
                    };
                    defer.resolve(uploadedFile);
                } catch (err) {
                    defer.reject(err);
                }
            }

            return defer.promise;
        }

        async function validate_cert() {
            try {
                $rootScope.spinner.show();
                vm.uploadedFile = await read_uploaded_file();
                var result = await $http.post("~/api/v1/settings/domain/ssl-cert-validate", JSON.stringify(vm.uploadedFile));
                if (result.data && !result.data.success)
                    throw result;
                vm.validateResult = result.data;
                return true;
            } catch (err) {
                errorHandling.report(err)
                return false;
            } finally {
                $rootScope.spinner.hide();
            }
        }

        async function upload_cert() {
            try {
                $rootScope.spinner.show();

                var result = await $http.post("~/api/v1/settings/domain/ssl-cert-upload", JSON.stringify(vm.uploadedFile));
                if (result.data && !result.data.success)
                    throw result;
                else
                    $mdDialog.hide();

            } catch (err) {
                errorHandling.report(err)
            } finally {
                $rootScope.spinner.hide();
            }
        }

        async function validate_hostnames() {
            try {
                $scope.$applyAsync(() => vm.checkingHostnames = true);
                let params = {
                    hostnames: vm.hostnamesFromText
                };

                var result = await $http.post("~/api/v1/info/validate-remote-instance-ids", JSON.stringify(params));
                if (result.data && !result.data.success)
                    throw result;
                else {
                    vm.hostnamesToGenerate = [];
                    for (let i = 0; i < result.data.items.length; i++) {
                        if (result.data.items[i].isMatch)
                            vm.hostnamesToGenerate.push(result.data.items[i].hostname);
                    }
                    $scope.$applyAsync(() => vm.page = "generate-cert");
                }

            } catch (err) {
                errorHandling.report(err)
            } finally {
                $scope.$applyAsync(() => vm.checkingHostnames = false);
            }
        }

        async function save_generate_config() {
            try {
                $rootScope.spinner.show();
                let params = {
                    hostnames: vm.hostnamesToGenerate
                };

                var result = await $http.post("~/api/v1/settings/domain/ssl-cert-generate", JSON.stringify(params));
                if (result.data && !result.data.success)
                    throw result;
                else {
                    $scope.$applyAsync(() => vm.page = "generate-cert-done");
                }

            } catch (err) {
                errorHandling.report(err)
            } finally {
                $rootScope.spinner.hide();
            }
        }

        function back() {
            vm.page = 'start';
        }

        function cancel() {
            $mdDialog.cancel();
        }

        function clone(ob) {
            return JSON.parse(JSON.stringify(ob));
        }
    }
})();
